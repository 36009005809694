<template>
<div class="aboutus col">
      <div class="row justify-content-center">
           
        <card style="width:100rem">
           <div style="height:7rem;width:100%" class="Onas-div">
              <h1 class="nadpis" >O nás</h1>
          </div>
        <div class="row">
          <card style="width:28rem">  
            <img width="100%" height="100%"  :src="require('@/assets/logojz.png')" style="margin:0em 1em 0em 0em" alt="logo">
            </card>
          <card class="text-center" style="width:45rem;margin-top:-3em;background-color:#ffffff00">
          <h2 class="aboutus-nadpis">Zabýváme se vývojem softwaru na zakázku a správou hardwaru.</h2>
          <p>Jsme mladá česká firma a zabýváme se komplexním vývojem softwaru na zakázku a správou hardwaru. Poskytujeme také odbornou konzultaci a podporu při realizaci projektů a hledáme efektivní řešení. Vyvíjíme jak pro firmy a výzkumné instituce, tak i pro fyzické osoby a jednotlivce.</p>
        </card>
        </div>
        </card>
      </div>
</div>
</template>

<script>
import Header from './components/HeaderP.vue';
import Card from "../components/Cards/Card.vue"
export default {
  name: 'AboutUs',
  components:{
    Card,
  }
};
</script>
<style>
.aboutus-nadpis{
    font-size: 1.825em !important;
    margin-bottom: 30px !important;
    line-height: 1.4em  !important;
}
.nadpis-onas{
margin-left: 1em;
}
.Onas-div{
    margin:5em 0 3em 5em ;
    color: rgb(0, 0, 0);
    }
@media only screen and (max-width: 40em) {
  .Onas-div{
   text-align: center;
   margin:5em 0 3em 0em ;
    }
}
.aboutus{
  margin: 3em 0 0 0;
}

img{
  float: left;
  position:static ;
}

p{
    font-size: 2em;
}

</style>
