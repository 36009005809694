<template>
      <div class="col">
                <Header nadpis="Náš Tým"></Header>
                <div class="row justify-content-center">
                  <card class="text-center " style="width:23rem;" >
                    <svg class="svg-icon" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 122.88 116.02"><defs></defs><title>person</title><path class="cls-1" d="M45.41,69.63c.35-2.91-8.3-14.06-9.88-19.4-3.39-5.38-4.59-13.94-.9-19.63,1.47-2.26.84-4.22.84-7.35,0-31,54.27-31,54.27,0,0,3.92-.89,4.84,1.22,7.93,3.55,5.15,1.73,14.29-1.27,19.07-1.93,5.61-11,16.22-10.37,19.4.57,15.89-34,15.38-33.91,0v0Zm19.78,27.5H67.1A3.13,3.13,0,0,0,70.22,94V89a3.12,3.12,0,0,0-3.12-3.12H55.79A3.13,3.13,0,0,0,52.67,89V94a3.14,3.14,0,0,0,3.12,3.12h1.94L54,116H68.76L65.19,97.13ZM0,116C1.47,97-2.26,97.79,13.65,91.84A122.7,122.7,0,0,0,36,80.54L49.6,116Zm87.54-37.1a91.08,91.08,0,0,0,20.61,10.29C123,94.15,123,94.84,122.86,116H73.45l14.09-37.1Z"/></svg>
                    <div>
                    <p class=" card-title team-names">Emanuel Soule</p>
                    <p class="card-subtitle mb-2 text-muted team-pozice">CEO, jednatel</p>
                    <p class="card-text">
                      <mail-icon style="margin-right:1rem;" />
                      <a href="mailto:e.soule@czechlite.software" class="card-link"> e.soule@czechlite.software</a>
                    </p>
                    </div>
                  </card>
                    <card class="text-center" style="width:23rem;" >
                    <svg  class="svg-icon" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 122.88 116.02"><defs></defs><title>person</title><path class="cls-1" d="M45.41,69.63c.35-2.91-8.3-14.06-9.88-19.4-3.39-5.38-4.59-13.94-.9-19.63,1.47-2.26.84-4.22.84-7.35,0-31,54.27-31,54.27,0,0,3.92-.89,4.84,1.22,7.93,3.55,5.15,1.73,14.29-1.27,19.07-1.93,5.61-11,16.22-10.37,19.4.57,15.89-34,15.38-33.91,0v0Zm19.78,27.5H67.1A3.13,3.13,0,0,0,70.22,94V89a3.12,3.12,0,0,0-3.12-3.12H55.79A3.13,3.13,0,0,0,52.67,89V94a3.14,3.14,0,0,0,3.12,3.12h1.94L54,116H68.76L65.19,97.13ZM0,116C1.47,97-2.26,97.79,13.65,91.84A122.7,122.7,0,0,0,36,80.54L49.6,116Zm87.54-37.1a91.08,91.08,0,0,0,20.61,10.29C123,94.15,123,94.84,122.86,116H73.45l14.09-37.1Z"/></svg>
                    <div>
                    <p class="card-title team-names">Zdeněk Landa</p>
                    <p class="card-subtitle mb-2 text-muted team-pozice">CTO</p>
                    <p class="card-text">
                      <mail-icon style="margin-right:1rem;" />
                      <a href="mailto:z.landa@czechlite.software" class="card-link"> z.landa@czechlite.software</a>
                    </p>
                    </div>
                  </card>
                    <card class="text-center" style="width:23rem;">
                    <svg class="svg-icon" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 122.88 116.02"><defs></defs><title>person</title><path class="cls-1" d="M45.41,69.63c.35-2.91-8.3-14.06-9.88-19.4-3.39-5.38-4.59-13.94-.9-19.63,1.47-2.26.84-4.22.84-7.35,0-31,54.27-31,54.27,0,0,3.92-.89,4.84,1.22,7.93,3.55,5.15,1.73,14.29-1.27,19.07-1.93,5.61-11,16.22-10.37,19.4.57,15.89-34,15.38-33.91,0v0Zm19.78,27.5H67.1A3.13,3.13,0,0,0,70.22,94V89a3.12,3.12,0,0,0-3.12-3.12H55.79A3.13,3.13,0,0,0,52.67,89V94a3.14,3.14,0,0,0,3.12,3.12h1.94L54,116H68.76L65.19,97.13ZM0,116C1.47,97-2.26,97.79,13.65,91.84A122.7,122.7,0,0,0,36,80.54L49.6,116Zm87.54-37.1a91.08,91.08,0,0,0,20.61,10.29C123,94.15,123,94.84,122.86,116H73.45l14.09-37.1Z"/></svg>
                    <div>
                    <p class="card-title team-names">Bc. Adam Freja</p>
                    <p class="card-subtitle mb-2 text-muted team-pozice">CIO</p>
                    <p class="card-text">
                      <mail-icon style="margin-right:1rem;" />
                      <a href="mailto:a.freja@czechlite.software" class="card-link"> a.freja@czechlite.software</a>
                    </p>
                    </div>
                  </card>
                </div>
                    <div class="row justify-content-center">
                    <card class="text-center" style="width:23rem;">
                      <svg class="svg-icon" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 122.88 116.02"><defs></defs><title>person</title><path class="cls-1" d="M45.41,69.63c.35-2.91-8.3-14.06-9.88-19.4-3.39-5.38-4.59-13.94-.9-19.63,1.47-2.26.84-4.22.84-7.35,0-31,54.27-31,54.27,0,0,3.92-.89,4.84,1.22,7.93,3.55,5.15,1.73,14.29-1.27,19.07-1.93,5.61-11,16.22-10.37,19.4.57,15.89-34,15.38-33.91,0v0Zm19.78,27.5H67.1A3.13,3.13,0,0,0,70.22,94V89a3.12,3.12,0,0,0-3.12-3.12H55.79A3.13,3.13,0,0,0,52.67,89V94a3.14,3.14,0,0,0,3.12,3.12h1.94L54,116H68.76L65.19,97.13ZM0,116C1.47,97-2.26,97.79,13.65,91.84A122.7,122.7,0,0,0,36,80.54L49.6,116Zm87.54-37.1a91.08,91.08,0,0,0,20.61,10.29C123,94.15,123,94.84,122.86,116H73.45l14.09-37.1Z"/></svg>
                      <div>
                      <p class="card-title team-names">Bc. Antonín Rameš</p>
                      <p class="card-subtitle mb-2 text-muted team-pozice">Backend Developer</p>
                      <p  class="card-text">
                        <mail-icon style="margin-right:1rem;" />
                        <a href="mailto:a.rames@czechlite.software" class="card-link">a.rames@czechlite.software</a>
                      </p>
                      </div>
                    </card>
                        <card class="text-center" style="width:23rem;">
                      <svg class="svg-icon" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 122.88 116.02"><defs></defs><title>person</title><path class="cls-1" d="M45.41,69.63c.35-2.91-8.3-14.06-9.88-19.4-3.39-5.38-4.59-13.94-.9-19.63,1.47-2.26.84-4.22.84-7.35,0-31,54.27-31,54.27,0,0,3.92-.89,4.84,1.22,7.93,3.55,5.15,1.73,14.29-1.27,19.07-1.93,5.61-11,16.22-10.37,19.4.57,15.89-34,15.38-33.91,0v0Zm19.78,27.5H67.1A3.13,3.13,0,0,0,70.22,94V89a3.12,3.12,0,0,0-3.12-3.12H55.79A3.13,3.13,0,0,0,52.67,89V94a3.14,3.14,0,0,0,3.12,3.12h1.94L54,116H68.76L65.19,97.13ZM0,116C1.47,97-2.26,97.79,13.65,91.84A122.7,122.7,0,0,0,36,80.54L49.6,116Zm87.54-37.1a91.08,91.08,0,0,0,20.61,10.29C123,94.15,123,94.84,122.86,116H73.45l14.09-37.1Z"/></svg>
                      <div>
                      <p class="card-title team-names">Lukáš Szalay</p>
                      <p class="card-subtitle mb-2 text-muted team-pozice">Frontend Developer</p>
                      <p  class="card-text">
                        <mail-icon style="margin-right:1rem;" />
                        <a href="mailto:l.szalay@czechlite.software" class="card-link">l.szalay@czechlite.software</a>
                      </p>
                      </div>
                    </card>
                </div>
        </div>
</template>

<script>
import { MailIcon } from "vue-tabler-icons";
import Header from './components/HeaderP.vue'
import Card from "../components/Cards/Card.vue"

export default {
  name: 'Team',
  components:{
    MailIcon,
    Header,
    Card
  }
};
</script>

<style>
.team-pozice{
    font-weight: 400 !important;
    font-size: 1.3em !important;
    line-height: 1.4em !important;
}
.team-names{
    font-size: 1.5em !important;
    line-height: 1.45em !important;
    margin-top: 30px !important;
    font-weight: 400 !important;
}
.card{
  margin-left:1em;

}
a{
  color: #58beee;
}
.Name{
  margin:0px 0px 0px 0px;
}
.PoziceVeFirme{
  color: rgb(17, 152, 241);
}
p{
  margin:0px 0px 0px 0px;
}
.osoby-info{
 margin-bottom: 0px;
}
.firma-info{
  margin:15px 0px 0px 0px;
}
</style>