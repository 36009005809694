<template>
  <div style="margin-top:5em" class="container justify-content-center">
    <div class="row justify-content-center">
      <div class="col-xl-8 col-l-10 col-md-10 col-sm-12">
        <h2 class="referenceHeading">Informační systém nákladů</h2>
        <hr class="services-hr">
        <p class="referenceDescription">Informační systém pro schvalování nákladů mezi spolupracujícími společnostmi, který slouží k zapisování a schvalování nákladů ze společných nebo interních činností, nákupu materiálů a ke správě výdajů jednotlivých společností.</p>
      </div>
  </div>
    <div class="row justify-content-center">
      <div class="col-xl-12 col-l-12 col-md-12 col-sm-12 ">
        <img class="imgShadow " src="../../assets/tableRef1.png" />
        <p class="referenceDescription">Informační systém nabízí editaci uživatele, včetně změny uživatelských údajů a změny hesla. </p>
        <hr class="services-hr">
      </div>
      <div class="col-xl-12 col-l-12 col-md-12 col-sm-12 ">
        <img class="imgShadow" src="../../assets/tableRef2.png" />
        <p class="referenceDescription">Systém obsahuje dvě schvalovací tabulky, jednu pro interní správu a sekundární pro schválení nákladů partnerských subjektů.</p>
        <hr class="services-hr">
      </div>
      <div class="col-xl-12 col-l-12col-md-12 col-sm-12 ">
        
        <img class="imgShadow " src="../../assets/tableRef4.png" />
        <p class="referenceDescription">Pro zaznamenání náležitostí spjatých s pořízením nákladů slouží tabulka náklady.</p>
        <hr class="services-hr">      
      </div>
        <div class="col-xl-12 col-l-12 col-md-12 col-sm-12 ">
        <img class="imgShadow" src="../../assets/tableRef3.png" />
        <p class="referenceDescription">Grafický přehled a výpis všech nákladů nabízí v systému záložka souhrn.</p>
        <hr class="services-hr">
      </div>
  </div>

</div>
</template>
  
<script>

export default {
  data() {
    return {
     image:"../../assets/jjmechanics2.png"
    };
  },
    name:"AeroReference",
    methods:{
      async scrollTo(selector){
      try{
       document.querySelector(selector).scrollIntoView({behavior:'smooth'});
      }catch{
      await this.$router.push('/')
      document.querySelector(selector).scrollIntoView({behavior:'smooth'});
      }
    },
    }
}
</script>

<style>
.img{
  margin-bottom:15px
}
.imgShadow{
  padding-left:0px !important;
  padding-right:0px !important;
    margin-bottom:30px;
    box-shadow: rgb(0 0 0 / 25%) 6px 6px 15px 10px;
}
.corouselText{
  color:black;
}
.arrow-button-back {
  display: flex;
  color: #c2ffe9;
  background-color: #1592cd;
  padding: 10px 16px;
  transition: all 0.3s ease;
  cursor: pointer;
  align-items: center;
  font-size: 18px;
  border: none;
}

.arrow-button-back > .arrow-back {
  width: 6px;
  height: 6px;
  border-right: 2px solid #c2ffe9;
  border-bottom: 2px solid #c2ffe9;
  position: relative;
  transform: rotate(135deg);
  margin: 0 6px;
  transition: all 0.3s ease;
}

.arrow-button-back > .arrow-back::before {
  display: block;
  background-color: currentColor;
  width: 3px;
  transform-origin: bottom right;
  height: 2px;
  position: absolute;
  opacity: 0;
  bottom: calc(-2px / 2);
  transform: rotate(45deg);
  transition: all 0.3s ease;
  content: "";
  right: 0;
}

.arrow-button-back:hover > .arrow-back {
  transform: rotate(135deg) translate(4px, 4px);
  border-color: text-hover-color;
}

.arrow-button-back:hover > .arrow-back::before {
  opacity: 1;
  width: 10px;
}

.arrow-button-back:hover {
  background-color: #44a1cc;
  color: #fff;
}

.bottom-left {
 position: absolute;
left:    0;
bottom:   0;
}
.referenceDescription{
text-align:center;
}
.referenceHeading{
margin-bottom:0px;
text-align:center;
}
</style>