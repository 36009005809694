<template>
  <navbar 
    position="fixed"
    type="dark"
    :transparent="false"
    menu-classes="ml-auto"
  >
    <template>
    <router-link class="nav-item" to="/">
        <a href="#intro" @click.prevent="scrollTo('#intro')">CzechLite Software</a>   
      </router-link>
   
    </template>
    <template slot="navbar-menu">
      <div>
      <li class="nav-item">
          <a href="#about-us" @click.prevent="scrollTo('#about-us')" class="nav-link">O nás</a>
      </li>
      </div>
    
       <div>
      <li class="nav-item">
          <a  href="#services" @click.prevent="scrollTo('#services-list')" class="nav-link">Služby</a>
      </li>
      </div>
       <!--<div>
      <li class="nav-item">
          <a  href="#services" @click.prevent="scrollTo('#references')" class="nav-link">Reference</a>
      </li>
      </div>
   <div>
      <li class="nav-item">
          <a href="#team" @click.prevent="scrollTo('#team')" class="nav-link">Náš tým</a>
      </li>
      </div>-->
      <div>
      <li class="nav-item">
          <a href="#team" @click.prevent="scrollTo('#contact')"  class="nav-link">Kontakt</a>
      </li>
      </div>
     

      <li  class="nav-item">
        <a
          class="nav-link"
          rel="tooltip"
          title="Sledujte nás na LinkedInu"
          data-placement="bottom"
          href="https://cz.linkedin.com/company/czechlite-software"
          target="_blank"
        >
     <svg  viewBox="0 0 24 24" width="18" height="18" xmlns="http://www.w3.org/2000/svg" shape-rendering="geometricPrecision" text-rendering="geometricPrecision" image-rendering="optimizeQuality" fill-rule="evenodd" clip-rule="evenodd">
        <g>
          <path stroke="null" fill="#ffffff" d="m21.88587,-0.07354l-19.6398,0c-1.24637,0 -2.26614,1.01976 -2.26614,2.26614l0,19.63984c0,1.24637 1.01976,2.26614 2.26614,2.26614l19.6398,0c1.24637,0 2.26614,-1.01976 2.26614,-2.26614l0,-19.63984c0,-1.24637 -1.01976,-2.26614 -2.26614,-2.26614zm-12.84174,19.6398l-3.02095,0l0,-10.57556l3.02095,0l0,10.57556zm-1.51091,-12.08651c-0.83507,0 -1.51049,-0.67538 -1.51049,-1.51049c0,-0.83552 0.67538,-1.51091 1.51049,-1.51091c0.83552,0 1.51091,0.67538 1.51091,1.51091c0,0.83507 -0.67538,1.51049 -1.51091,1.51049zm12.08651,12.08651l-3.02099,0l0,-6.0428c0,-0.83552 -0.6758,-1.51091 -1.51091,-1.51091c-0.83552,0 -1.51091,0.67538 -1.51091,1.51091l0,6.0428l-3.02189,0l0,-10.57556l3.02189,0l0,1.87492c0.62228,-0.85562 1.57693,-1.87492 2.64356,-1.87492c1.87938,0 3.3992,1.69069 3.3992,3.77708l0,6.79848l0.00004,0z" id="svg_1"/>
        </g>
</svg>

          <p class="d-lg-none d-xl-none icon-odsazeni">LinkedIn</p>
        </a>
      </li>
      
    
    </template>
  </navbar>
</template>

<script>

import { Navbar } from '@/components';
import { Popover } from 'element-ui';
export default {
  name: 'main-navbar',
  methods:{
  async scrollTo(selector){
      try{
       document.querySelector(selector).scrollIntoView({behavior:'smooth'});
      }catch{
      await this.$router.push('/')
      document.querySelector(selector).scrollIntoView({behavior:'smooth'});
      }
    },
  },
  props: {

    transparent: Boolean,
    colorOnScroll: Number
  },
  components: {
    
    Navbar,
    
    [Popover.name]: Popover
  }
};

</script>

<style >
.icon-odsazeni{
  margin-left:5px !important;
}
.nav-item{
  font-size: 1.3rem;
}
.nav-link{
cursor: pointer;
}
</style>
