<template>
  <div class="">
        <div class="header-mobile page-header clear-filter" >
      <parallax
        class="page-header-image"
        style="background-image:url('img/HeaderLogo.png')"
        
      >
      </parallax>
     
    </div>
  </div>
</template>

<script>

export default {
    name:"Intro",
    components:{
    }

}
</script>

<style>
@media only screen and (max-width: 40em) {
  .header-mobile {
       min-height: 20vh !important;
       max-height: 350px !important;
  }
}
#Intro{
    margin: 5em 0em 3em 0em;
    box-shadow: none;
    -webkit-box-shadow:none;
}
</style>

