<template>
  <div class="contact">
          <ContactInfo></ContactInfo>
        
  </div>
</template>
<script>

import ContactInfo from './ContactInfo.vue'

export default {
  name: 'Contact',
  components:{
    ContactInfo,
  }
};
</script>

<style>
.Line{
 width: 100%;
 border-top: 3px solid rgb(17, 152, 241) ;
}
a{
  color: #58beee;
}
.Name{
  margin:0px 0px 0px 0px;
}
.PoziceVeFirme{
  color: rgb(17, 152, 241);
}
h3{
  margin:0px 0px 0px 0px;
}
.osoby-info{
 margin-bottom: 0px;
}
.firma-info{
  margin:15px 0px 0px 0px;
}
</style>