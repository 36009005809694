<template>
<div class="col">
   <Header nadpis="Ozvěte se nám"></Header>
          <div class="row justify-content-center">
             <card style="width:47rem;margin-left:1em" class="text-center">
               <h2 class="contact-form-nadpis text-center card-title">Kontaktní formulář</h2>
                   <hr class="services-hr">  
                <h4  class="services-header text-center">Zaujala vás některá z našich služeb?</h4>
               <p>Máte nápad na projekt nebo potřebujete s něčím poradit? Napište nám skrze kontaktní formulář a my se vám ozveme.</p>
             </card>
        <card class="Odsazeni h4" style="width:47rem;margin-left:1em" >
           <form  onsubmit="return false;">
                        <div class="form-row">
                          <!-- 
                          <div class="form-group col">
                            
                            <label>Váš email:</label>
                              <input required v-model="postD.senderD" name="Email" type="email" class="form-control" placeholder="Váš email..">
                          </div>
                          -->
                             <div class="form-group col">
                            <label>Předmět:</label>
                              <input minlength="5" required v-model="postD.subjectD" name="Subject" type="text" class="form-control" placeholder="Předmět..">
                          </div>
                        </div>
                         <div class="form-row">
                               <div class="col">
                            <label for="popisprojektu">Popis projektu:</label>
                             <textarea minlength="10" required v-model="postD.messageD" id="popisprojektu" name="TextPart" class="form-control" rows="3"></textarea>
                          </div>
                          </div>
                           <div class="form-row bottom-right">
                          <button @click="mailto()" id="btmD"  class="btnn body">
                                  <i  class='bx bx-send'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" style="fill: rgba(255, 255, 255, 1);transform: ;msFilter:;"><path d="m21.426 11.095-17-8A.999.999 0 0 0 3.03 4.242L4.969 12 3.03 19.758a.998.998 0 0 0 1.396 1.147l17-8a1 1 0 0 0 0-1.81zM5.481 18.197l.839-3.357L12 12 6.32 9.16l-.839-3.357L18.651 12l-13.17 6.197z"></path></svg>
                                  </i>
                                  <span class="text">
                                   Odeslat
                                  </span>
                                  <span class="loading-animate"></span>
                                </button>
                          </div>
           </form>
        </card>
          </div>
        
</div>
</template>

<script>
import axios from 'axios'
import Card from "../../components/Cards/Card.vue"
import Header from './HeaderP.vue'
export default {
name:"ContactForm",
  data(){
    return{
      postQ:{
        subjectQ:"",
        messageQ:"",
        senderQ:""
        },
        postD:{
        subjectD:"",
        messageD:"",
        senderD:""
        }
    }
  },
components:{
    Card,
    Header
  },
  mounted(){
     
  },
methods: {
  mailto(){
    if(this.postD.subjectD.length>3 && this.postD.messageD.length>10){
    window.location.href = "mailto:info@czechlite.software?subject=" + this.postD.subjectD+"&body=" + this.postD.messageD;
    this.postD.subjectD=''
    this.postD.messageD=''
    }else{

    }
  },
   otocD(){
    var btm =document.getElementById('btmD')
    btm.classList.add('loading');
  setTimeout(() => {
   btm.classList.remove('loading')
  }, 2000);

  },

clearInputQ(){
      this.postQ={}
  },
clearInputD(){
      this.postD={}
  },
}
}

</script>
<style lang="css" >
.bx-send:before {
}
.bx{
    font-family: 'boxicons'!important;
    font-weight: normal;
    font-style: normal;
    font-variant: normal;
    line-height: 1;
    display: inline-block;
    text-transform: none;
    speak: none;
    -webkit-font-smoothing: antialiased;}
.body {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100vh;
        background: #F1F3F6;
    }
    .btnn {
        margin: 10px 0 10px 0;
        background:#1592cd;
        border: 0px solid #1592cd;
        border-radius: 35px;
        font-size: 1rem;
        font-weight: bold;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        outline: none;
        transition: all .25s ease;
        width: 10em;
        position: relative;
        height: 3.5em;
        overflow: hidden;
    }
    .btnn:not(.loading):hover {
        box-shadow: 0px 10px 25px 0px rgba(21,146,205,.4);
    }
    .btnn:not(.loading):hover i {
        transform: translate(7px) !important;
    }
    .btnn  i {
        font-size: 1.45rem !important;
        position: absolute !important;
        left: 0px !important;
        pointer-events: none !important;
        z-index: 10 !important;
        background: inherit !important;
        width: 60px !important;
        height: 60px !important;
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
        border-radius: 50% !important;
        transition: all .25s ease !important;
    }
    .btnn .text {
        width: 130px;
        display: block;
        position: relative;
        pointer-events: none;
        transition: all .25s ease;
        position: absolute;
        left: 2em;
    }
    .loading-animate {
        position: absolute;
        width: 60px;
        height: 60px;
        z-index: 100;
        border-radius: 50%;
        top: 0px;
        left: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        pointer-events: none;
        opacity: 0;
        transition: all .25s ease;
    }
    .loading-animate:after {
        content: '';
        width: 44px;
        height: 44px;
        border-radius: 50%;
        border: 3px solid transparent;
        border-left: 3px solid #fff;
        animation: loading infinite .8s ease .05s;
        position: absolute;
    }
    .loading-animate:before {
        content: '';
        width: 44px;
        height: 44px;
        border-radius: 50%;
        border: 3px solid transparent;
        border-left: 3px solid #fff;
        animation: loading infinite .8s linear;
        position: absolute;
        opacity: .6;
    }
    .btnn.loading {
        width: 60px;
    }
    .btnn.loading i {
        transform: rotate(-90deg);
        padding-bottom: 4px;
        padding-left: 3px;
    }
    .btnn.loading .text {
        transform: translate(-140px);
    }
    .btnn.loading .loading-animate {
        opacity: 1;
    }
    @keyframes loading {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }


.contact-form-nadpis{
  font-weight: 400 !important;
  font-size: 3.5em !important;
  line-height: 1.15 !important;
}
label{
    font-weight: bold;
}

</style>