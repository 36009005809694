<template>
  <div>
  <Intro id="intro"></Intro>
    <about-us id="about-us"/>
    <services-list id="services-list"/>
    <services/>
    <!--<References id="references"></References>
    <Team id="team"></Team>-->
    <ContactForm id="contact-form"></ContactForm> 
    <Contact id="contact"></Contact>
  </div>
</template>
<script>
import References from './References.vue'
import Intro from './Intro.vue'
import ContactForm from './components/ContactForm.vue'
import Team from './Team.vue'
import AboutUs from './AboutUs.vue';
import Contact from './Contact.vue'
import Services from './Services.vue'
import ServicesList from './ServicesList.vue'
export default {
  name: 'index',
  bodyClass: 'index-page',
  components: {
    //References,
    Intro,
    Services,
    AboutUs,
    ServicesList,
    ContactForm,
    //Team,
    Contact

  }
};
</script>
<style></style>
