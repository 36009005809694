<template>
  <div class="col">
   <Header nadpis="Co nabízíme"></Header>
  <div  class="row justify-content-center">
<card  style="width:47rem;margin-right:1em;">
   <h2 class="services-nadpis card-title text-center">Software <hr  class="services-hr">  </h2>
    <div>
      <ul class="seznam-list">
        <li class="polozka-list polozka-list-pozadi">Vývoj webových aplikací</li>
        <li class="polozka-list polozka-list-pozadi">Vývoj desktopových aplikací</li>
        <li class="polozka-list polozka-list-pozadi">Tvorba webových stránek</li>
      </ul>
      
       <div class="bottom-right">
      <button  href="#software" @click.prevent="scrollTo('#software')"   class="button-services-list btn btn-info">Zjistit více</button>
        </div>
    </div>
</card>
<card   style="width:47rem;">
  <h2 class="services-nadpis card-title text-center">Hardware</h2>
    <hr class="services-hr">  
    <div>
         <ul style="list-style-type:none;padding-left:0;font-size: 1.3em !important;line-height: 1.4em !important;">
        <li class="polozka-list polozka-list-pozadi">Outsourcing IT</li>
        <li class="polozka-list polozka-list-pozadi">Návrh a údržba osobních počítačů</li>
        <li class="polozka-list polozka-list-pozadi">Návrh a správa serverů a sítí</li>
      </ul>
     
        <div class="bottom-right">
      <button  href="#hardware" @click.prevent="scrollTo('#hardware')"  class="button-services-list btn btn-info">Zjistit více</button>
        </div>
    </div>
</card>
  </div>
  </div>
</template>

<script>
import Header from './components/HeaderP.vue';
import Card from '../components/Cards/Card.vue';
export default {
    name:"Services",
    components: {
      Card,
      Header
    },
    methods:{
         scrollTo(selector){
       document.querySelector(selector).scrollIntoView({behavior:'smooth'});
    },
    }
}
</script>

<style>
.polozka-list-pozadi{
  background-image: url(dot.svg);
}
.polozka-list{
  margin: 0 0 1rem!important;
  background-size: 18px;
  padding: 0 0 0 2em!important;
  list-style: none;
  background-repeat: no-repeat;
  background-position: left center;
}
.seznam-list{
  padding-left:0;
  font-size: 1.3em !important;
  line-height: 1.4em !important;
}
.services-offers{
  font-size: 1.3em !important;
  line-height: 1.4em !important;
}
.services-nadpis{
  font-weight: 400 !important;
  font-size: 3.5em !important;
  line-height: 1.15 !important;
}
.button-services-list{
  background-color:#1592cd !important;
}
.button-services-list:hover{
    background-color:#44a1cc !important;
}
.hr-services-list {
    height: 12px;
    border: 0;
    box-shadow: inset 0 12px 12px -12px rgba(81, 204, 253, 0.5);
}

.services-list{
  margin:9px 0 0 15px;
}

</style>