<template>
<div style="background-color:#3e3e3e" class="col">
          <div class="row justify-content-center">
                <card class="text-center kontakt" style="width:47rem;">
                 
                  <h2 class="contact-nadpis card-title text-center">CzechLite Software s.r.o.</h2>
                  <div style="margin-top:6em">
                    <p class="contact-info"><mail-icon style="margin-right:1rem;width:28px;height:28px" /><a href="mailto:info@czechlite.software">info@czechlite.software </a></p>
                    <p class="contact-info"><phone-icon style="margin-right:1rem;width:28px;height:28px" /><a href="tel:+420 774 449 172">+420 774 449 172</a></p>
                    <p class="contact-info"><map-icon style="margin-right:1rem;width:28px;height:28px" /><a href="https://www.google.com/maps/place/C.+Boudy+1444,+Kro%C4%8Dehlavy,+272+01+Kladno/@50.1374989,14.1010629,17z/data=!3m1!4b1!4m5!3m4!1s0x470bb7c213870663:0x77611b1991fd5afc!8m2!3d50.1374989!4d14.1032569" target="_blank">Cyrila Boudy 1444 Kladno 272 01</a></p>
                    <p class="contact-info"><strong>Datová Schránka</strong>: vvyd4ru</p>   
                    <p class="contact-info"><strong>IČ:</strong> 11774924 </p>
                  </div>
                </card>
             <card class="kontakt" style="width:47rem;">
                <h2 class="contact-nadpis card-tittle text-center">Kde nás najdete?</h2>
               <iframe title="Místo kde nás najdete" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2557.263682132313!2d14.10106821572012!3d50.137498879433515!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470bb7c213870663%3A0x77611b1991fd5afc!2sC.%20Boudy%201444%2C%20Kro%C4%8Dehlavy%2C%20272%2001%20Kladno!5e0!3m2!1scs!2scz!4v1647012532263!5m2!1scs!2scz" width="100%" height="350px" style="border:0;" allowfullscreen loading="lazy"></iframe>
             </card>
             
            </div>
</div>
</template>
<script>
import Header from './components/HeaderP.vue';
import { MailIcon,PhoneIcon,MapIcon } from "vue-tabler-icons";
import Card from '../components/Cards/Card.vue';
export default {
  name: 'Contact',
  components:{
    MapIcon,
    PhoneIcon,
    MailIcon,
    Card,
  }
};
</script>

<style>
.kontakt{
  width:47rem;
  background-color:#3e3e3e !important;
  color:white;
}
.contact-nadpis{
  font-weight: 400 !important;
  font-size: 3.5em !important;
  line-height: 1.15 !important;
  margin-bottom: 30px !important;
}
.contact-info{
  font-size: 1.5rem !important;
  margin:15px 0px 15px 0px;
  line-height: 1.4em !important;
  font-weight: 400 !important;
}
</style>
