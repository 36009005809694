<template>
  <div>
    <Header nadpis="Reference"></Header>
    <div class="container-fluid">
      <div class="row justify-content-center gy-5">
        <div style="width: 35em; margin-right: 15px">
          <div
            class="card border-0"
            style="box-shadow: 10px 10px 25px 1px #00000040"
          >
            <div class="img-hover-zoom">
              <a target="_blank"  href="https://www.jj-mechanics.cz/">
              <img
                class="card-img-top image" 
                src="../assets/jjmechanics2.png"
                alt="Autoservis"
              />
              </a>
            </div>

            <div class="card-body karta">
              <h4 class="Nomargin card-title kartaTitle">Autoservis</h4>
              <hr class="services-hr " />
          
              <div class="bottom-center">
                  <a target="_blank" href="https://www.jj-mechanics.cz/" class="buttonTxt">
                <button class="arrow-button">
                Přejít na stránku<span class="arrow"></span>
                </button></a>
              </div>
            </div>
          </div>
        </div>
        <div style="width: 35em; margin-right: 15px">
          <div
            class="card border-0"
            style="box-shadow: 10px 10px 25px 1px #00000040"
          >
            <div class="img-hover-zoom-Table">
              <a style="cursor:pointer;" target="_blank" @click="moreInformation()">
              <img
                class="card-img-top image"
                src="../assets/tableRef1.png"
                alt="sadsa"
              />
              </a>
            </div>
            <div class="karta card-body">
              <h4 class="Nomargin card-title kartaTitle">Informační systém nákladů</h4>
              <hr class="services-hr " />

              <div class="bottom-center">
                <button @click="moreInformation()" class="arrow-button">
                 <a class="buttonTxt"> Více informací<span class="arrow"></span></a>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "./components/HeaderP.vue";
export default {
  name: "References",
  components: {
    Header,
  },
  methods:{
    moreInformation(){
      this.$router.push('/TableCostRef')
    }
  }
};
</script>

<style>
.img-hover-zoom-Table {
  max-height: 240px; 
  overflow: hidden; 
}

.img-hover-zoom-Table img {
  transition: transform .5s ease;
}

.img-hover-zoom-Table:hover:active img {
  transform: scale(2);
}
.image{
}
.buttonTxt{
  text-decoration: none;
  color: #c2ffe9;
}
.buttonTxt:hover{
  text-decoration: none;
  color: #c2ffe9;
}
.bottom-center {
  display: grid;
  justify-content: center;
  position: relative;
  font-size: 1.2rem;
 
}
.arrow-button {
  display: flex;
  color: #c2ffe9;
  background-color: #1592cd;
  padding: 10px 16px;
  transition: all 0.3s ease;
  cursor: pointer;
  align-items: center;
  font-size: 18px;
  border: none;
}

.arrow-button > .arrow {
  width: 6px;
  height: 6px;
  border-right: 2px solid #c2ffe9;
  border-bottom: 2px solid #c2ffe9;
  position: relative;
  transform: rotate(-45deg);
  margin: 0 6px;
  transition: all 0.3s ease;
}

.arrow-button > .arrow::before {
  display: block;
  background-color: currentColor;
  width: 3px;
  transform-origin: bottom right;
  height: 2px;
  position: absolute;
  opacity: 0;
  bottom: calc(-2px / 2);
  transform: rotate(45deg);
  transition: all 0.3s ease;
  content: "";
  right: 0;
}

.arrow-button:hover > .arrow {
  transform: rotate(-45deg) translate(4px, 4px);
  border-color: text-hover-color;
}

.arrow-button:hover > .arrow::before {
  opacity: 1;
  width: 10px;
}

.arrow-button:hover {
  background-color: #44a1cc;
  color: #fff;
}

.referencesText {
  text-align: center;
  font-size: 1rem;
}
.referencesHeading {
}
.img-hover-zoom {
  overflow: hidden; /* [1.2] Hide the overflowing of child elements */
  max-height:240px
}
.img-hover-zoom img {
transform-origin: 50% 0%;
transition: transform 1s, filter .5s ease-out;}
.img-hover-zoom:hover:active  img {
  transform: scale(3);
}
.margin {
  margin-top: 15px;
}
.Nomargin {
  margin: 0px;
}
.karta {
  margin: 0px;
  padding: 0px;
}
.kartaTitle {
  text-align: center;
  font-weight: bold;
}
</style>