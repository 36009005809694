<template>
  <div id="app">
    <router-view name="header" />
    <div class="wrapper">
      <router-view />
    </div>
    <router-view name="footer" />
  </div>
</template>
<script>

export default {};
</script>
<style>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap');
#app{
font-family: 'Poppins', sans-serif;

}
html, body {
      min-width: 100%;
     min-height: 100%;
}
</style>