<template>
<div class="row">
    <div style="width:100%" class="Head">
  <h2 class="nadpis">{{nadpis}}</h2>
  <hr class="services-hr">  
  </div>
</div>
</template>

<script>
export default {
    name:"Header",
    props:{
        nadpis:String
    }
}
</script>

<style>
@media  only screen and (max-width: 40em) {
    .nadpis{
        font-size:15vw !important ;
    }
}
.nadpis{
    font-weight: 300;
    padding-top:4px ;
    margin-bottom:-10px ;
    font-size: 5rem;
}
.Head{
    margin-top:3em;
    margin-bottom:4em;
    color: rgb(0, 0, 0);
    text-align: center;
}
</style>